<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="9">
        <v-row
          v-if="$store.state.auth.includes('01073') || $store.state.isadmin"
        >
          <v-col cols="12" md="5">
            <v-card elevation="6" height="90" class="mt-3">
              <v-row>
                <v-col class="ml-4 mt-2 grow">
                  <b>
                    {{
                      "Chiffre d'affaire " +
                      "(" +
                      exercice +
                      ")" +
                      " : " +
                      numberWithSpace(decimalDigits(ca_exercice() / c)) +
                      " " +
                      unit
                    }}</b
                  >
                  <br />
                  <b> {{ "Objectif : " + kpi_value() + " " + unit }}</b>
                </v-col>
                <v-col class="mt-4 shrink">
                  <v-icon color="green darken-2" right large>
                    mdi-arrow-top-right-thin-circle-outline
                  </v-icon>
                </v-col>
                <v-col class="mr-4 mt-4 shrink">
                  <h2>{{ kpi_taux() + "%" }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card elevation="6" height="90">
              <v-card-title>
                <a @click="dettes_creances">
                  {{
                    "Total Créances " +
                    " : " +
                    numberWithSpace(decimalDigits(creance().cr)) +
                    " " +
                    unit
                  }}
                </a>
              </v-card-title>
              <v-card-subtitle
                :class="creance().cre > 0 ? 'red--text' : 'green--text'"
                >{{
                  "Echues à aujourdhui : " +
                  numberWithSpace(decimalDigits(creance().cre)) +
                  " " +
                  unit
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card elevation="6" height="90">
              <v-card-title>
                Plan de charge :
                {{ numberWithSpace(decimalDigits(cmd_act)) + " " + unit }}
              </v-card-title>
              <v-card-subtitle
                :class="cmd_retard > 0 ? 'red--text' : 'green--text'"
                >{{
                  "En Retard à aujourdhui : " +
                  numberWithSpace(decimalDigits(cmd_retard)) +
                  " " +
                  unit
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="$store.state.auth.includes('01073') || $store.state.isadmin"
        >
          <v-col cols="12" md="5">
            <v-card elevation="6" height="100%">
              <v-card-title>
                Répartition du CA par :
                <v-tabs right color="deep-purple accent-4">
                  <v-tab>Client </v-tab>
                  <v-tab>Categorie </v-tab>
                  <v-tab-item>
                    <v-container>
                      <listitems
                        :list="tier_list"
                        title="Par Tier"
                        :headers="tierheaders"
                        :master="false"
                        :key="klist1"
                        :hdf="true"
                        :istop="false"
                        :Total="true"
                        :ipg="-1"
                        :laoding="progress2"
                      >
                      </listitems>
                    </v-container>
                    <!-- <canvas id="planetchart2"></canvas> -->
                    <v-layout row justify-center align-center v-if="progress2">
                      <v-progress-linear
                        id="animationProgress"
                        indeterminate
                        style="width: 100%"
                      ></v-progress-linear>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            ref="type_cat"
                            v-model="categorieType"
                            :items="[
                              { id: 1, text: 'Tiers' },
                              { id: 2, text: 'Produits' },
                            ]"
                            :item-text="'text'"
                            :item-value="'id'"
                            label="Type categorie"
                            @change="CategorieChange(categorieType)"
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <selecttree
                            :items="list_cat"
                            v-model="cats"
                            :value="cats"
                            :selectable="false"
                            :label="'Categorie'"
                            :deletable_chips="false"
                            :select_type="'leaf'"
                            @input="cat_change"
                            :item_disabled="'locked'"
                            :closeoncontentclick="true"
                            :dense="true"
                          >
                          </selecttree>
                        </v-col>
                        <v-col cols="12" md="12" v-if="cat_list.length > 0">
                          <listitems
                            :list="cat_list"
                            title="Par Categorie"
                            :headers="catheaders"
                            :master="false"
                            :key="klist"
                            :hdf="true"
                            :istop="false"
                            :Total="true"
                            :ipg="-1"
                            :laoding="progress4"
                          >
                          </listitems>
                        </v-col>

                        <!-- <canvas id="planetchart3"></canvas> -->
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="7">
            <v-card elevation="6" height="100%">
              <v-card-title>
                Evolution du CA

                <v-tabs
                  v-model="tabs[1]"
                  v-if="!progress1 && bl"
                  @change="sit_change"
                  color="deep-purple accent-4"
                  right
                >
                  <v-tab> Cumulée </v-tab>
                  <v-tab> Facturation </v-tab>
                  <v-tab> Expedition </v-tab>
                </v-tabs>
              </v-card-title>
              <v-card-text>
                <canvas :id="1" height="100"></canvas>

                <v-layout row justify-center align-center v-if="progress1">
                  <v-progress-linear
                    id="animationProgress"
                    indeterminate
                    style="width: 100%"
                  ></v-progress-linear>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-tabs
                  v-model="ca_by"
                  v-if="!progress1"
                  @change="periode_change"
                  color="deep-purple accent-4"
                  right
                >
                  <v-tab> Année </v-tab>
                  <v-tab> dernier mois </v-tab>
                  <v-tab> derniere semaine </v-tab>
                </v-tabs>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="3">
        <alertes></alertes>
      </v-col>
    </v-row>

    <!-- Expliquer cet element -->
    <v-row>
      <v-col cols="12" md="12">
        <canvas id="product" height="100"></canvas>
        <v-layout row justify-center align-center v-if="progress3">
          <v-progress-linear
            id="animationProgress"
            indeterminate
            style="width: 100%"
          ></v-progress-linear>
        </v-layout>
      </v-col>
    </v-row>

    <!-- ------------------------------------------- -->

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <formlist
      :title="title"
      :list="list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="closeListForm"
      :width="width"
      :Total="true"
      :master="true"
      :orientation="orientation"
    >
    </formlist>
  </v-container>
</template>

<script>
function numberWithSpace(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
}
import Chart from "chart.js";
import CA_MOIS from "../graphql/Stat/CA_MOIS.gql";
//import CA_AN1 from "../graphql/Stat/CA_AN1.gql";
import CA_DAY from "../graphql/Stat/CA_DAY.gql";
import CA_WEEK from "../graphql/Stat/CA_WEEK.gql";
import CA_TIER from "../graphql/Stat/CA_TIER.gql";
import CA_TIER_CAT from "../graphql/Stat/CA_TIER_CAT.gql";
import CA_PRODUCT from "../graphql/Stat/CA_PRODUCT.gql";
import ALLDETAILSCMDS from "../graphql/Commande/ALLDETAILSCMDS.gql";
import ALLORDERS from "../graphql/Production/ALLORDERS.gql";
import DATA from "../graphql/Stat/DATA.gql";
import CMD_ACTUAL from "../graphql/Stat/CMD_ACTUAL.gql";
import SIT_TIERS_ALL from "../graphql/Tier/SIT_TIERS_ALL.gql";
import { myLogo, myName, myBL } from "print/data.js";

var unit_ca = "DA";

// var dynamicColors = function () {
//   var r = Math.floor(Math.random() * 255);
//   var g = Math.floor(Math.random() * 255);
//   var b = Math.floor(Math.random() * 255);
//   return "rgb(" + r + "," + g + "," + b + ")";
// };

// const planetChartData2 = {
//   type: "doughnut",
//   data: {
//     labels: [],
//     datasets: [
//       {
//         label: "",
//         backgroundColor: [],
//         data: [],
//         circumference: "180",
//         borderWidth: 2,
//         borderColor: "blue",
//         offset: 50,
//       },
//     ],
//   },
//   options: {
//     title: {
//       display: true,
//       position: "bottom",
//       text: "Repartition du chiffre d'affaire par categorie",
//     },
//     tooltips: {
//       callbacks: {
//         label: function (tooltipItem, data) {
//           var label = data.labels[tooltipItem.index] || "";

//           if (label) {
//             label += " : ";
//           }
//           label +=
//             numberWithSpace(data.datasets[0].data[tooltipItem.index]) + " DA";
//           let taux = 0;

//           if (data.datasets[0].data[tooltipItem.index]) {
//             taux =
//               (data.datasets[0].data[tooltipItem.index] /
//                 data.datasets[0].data.reduce((a, b) => a + (b || 0), 0)) *
//               100;
//             taux = parseFloat(taux.toFixed(2));
//             label += " (" + taux + "%)";
//           }

//           return label;
//         },
//       },
//     },
//   },
// };
function compare(a, b) {
  if (a.ttc_gl < b.ttc_gl) return 1;
  if (a.ttc_gl > b.ttc_gl) return -1;
  return 0;
}

export default {
  components: {
    selecttree: () => import("../components/SelectTree.vue"),
    formlist: () => import("../components/FormList.vue"),
    listitems: () => import("../components/ListItems.vue"),
    alertes: () => import("../components/Widgets/Alertes.vue"),
  },
  data() {
    return {
      c: 1,
      taux: 5,
      orientation: "p",
      width: "1000px",
      title: "",
      isListClose: true,
      listheaders: [],
      facture: ["1"],
      tabs: [],
      active: false,
      categorieType: 0,
      cats: [],
      chart_data: [],
      chart: [],
      list_cat: [],
      list_cat_all: [],
      tiers: [],
      cmds: [],
      mois_n: [],
      mois_n1: [],
      exercices: [],
      creance_list: [],
      creance_echu: [],
      Loading: false,
      mois: "",
      ca_mois: 0,
      cmd_act: 0,
      cmd_retard: 0,
      unit: "",
      exercice: 0,
      kpi: [],
      progress1: true,
      progress2: false,
      progress3: false,
      progress4: false,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      bl: false,
      ch_tier: null,
      ch_cat: null,
      ch1: 0,
      ch2: 0,
      cat_list: [],
      klist: 0,
      klist1: 100,
      tier_list: [],
      pdfUrl: "http://localhost/gabok-api/public/api/Certificate12.pdf",
      tierheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Tier",
          align: "start",
          value: "tier_name",
          selected: true,
        },
        {
          text: "Montant",
          value: "ttc_gl",
          align: "end",
          slot: "cur",
          total: "ttc_gl",
          width: "150px",
          selected: true,
        },
        {
          text: "%",
          value: "prc_ca",
          align: "center",
          slot: "progress",
          width: "90px",
          selected: true,
        },
      ],
      catheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Categorie",
          align: "start",
          value: "categorie_name",
          selected: true,
        },
        {
          text: "Montant",
          value: "ttc_gl",
          align: "end",
          slot: "cur",
          total: "ttc_gl",
          selected: true,
        },
        {
          text: "%",
          value: "prc_ca",
          align: "center",
          slot: "progress",
          width: "100px",
          selected: true,
        },
      ],
      mois_list: [
        "Janvier",
        "Fevrier",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Decembre",
      ],
      dettes_headers: [
        {
          text: "Code",
          value: "code_client",
          totlib: true,
          selected: true,
        },
        {
          text: "Nom",
          align: "start",
          value: "nom",
          selected: true,
        },
        {
          text: "Sit Ini.",
          value: "situation_ini",
          align: "end",
          slot: "cur",
          total: "situation_ini",
          selected: true,
        },
        {
          text: "Debit",
          value: "tot_fact",
          align: "end",
          slot: "cur",
          total: "tot_fact",
          selected: true,
        },

        {
          text: "Credit",
          value: "paye_fact",
          align: "end",
          total: "paye_fact",
          slot: "cur",
          selected: true,
        },

        {
          text: "Situation",
          value: "situation_fact",
          align: "end",
          slot: "cur",
          total: "situation_fact",
          selected: true,
        },
      ],
      list: [],
      ca_by: 0,
      days: [],
      weeks: [],
      fl: 200,
    };
  },
  mounted() {},
  async created() {
    if (
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    ) {
      this.bl = true;
      this.facture.push("0");
    }
    var options = { month: "long" };
    var aujd = new Date();
    this.mois = new Intl.DateTimeFormat("fr-FR", options).format(aujd);
    if (this.$store.state.exercice) {
      this.exercice = this.$store.state.exercice;
      // r = await this.requette(CA_AN1, { annee: this.exercice - 1 });
      // if (r) {
      //   this.mois_n1 = r.ca_results;
      // }

      let v = {
        year: this.exercice,
        year_n1: this.exercice - 1,
        tiertype: 2,
      };

      let m = 1;
      let r = await this.requette(CA_MOIS, v);
      if (r) {
        this.mois_n = r.ca_n;
        this.mois_n1 = r.ca_n1;
      }

      r = await this.requette(CA_DAY, v);
      if (r) {
        this.days = r.ca_day;
      }
      r = await this.requette(CA_WEEK, v);
      if (r) {
        this.weeks = r.ca_week;
      }
      v = {
        year: this.exercice,
        facture: this.facture,
        tiertype: 2,
      };
      r = await this.requette(DATA, v);
      if (r) {
        this.list_cat_all = r.categorie;

        //  if (this.cmd_act > m) m = this.cmd_act;

        this.exercices = r.exercices.filter(
          (elm) => elm.exercice == this.exercice
        );
        this.creance_echu = r.creance_echu;
        this.creance_list = r.creance;

        if (this.creance_list[0].creance > m) m = this.creance_list[0].creance;
        if (Math.max(...this.exercices.map((elm) => elm.kpi * elm.unit)) > m)
          m = Math.max(...this.exercices.map((elm) => elm.kpi * elm.unit));
      }
      if (m > 1000) {
        this.c = 1000;
        unit_ca = "KDA";
      }
      if (m > 1000000) {
        this.c = 1000000;
        unit_ca = "MDA";
      }

      this.unit = unit_ca;
      this.progress1 = false;
      if (
        this.$store.state.auth.includes("01073") ||
        this.$store.state.isadmin
      ) {
        this.load_data();
        this.load_tier(0);
      }
      let r2 = await this.requette(CMD_ACTUAL, v);
      if (r2) {
        this.cmd_act = r2.sitglob[0].ValCmdEncours;
        this.cmd_retard = r2.sitglob[0].ValCmdRetard;
        this.cmd_act = parseFloat((this.cmd_act / this.c).toFixed(2));
        this.cmd_retard = parseFloat((this.cmd_retard / this.c).toFixed(2));
      }
    }
  },
  watch: {
    taux: {
      handler() {
        if (this.taux > 0) this.load_tier(0);
      },
      deep: true,
    },
  },
  computed: {
    myname() {
      return myName;
    },
    mylogo() {
      return myLogo;
    },
  },
  methods: {
    data_output(data, col) {
      var obj2 = [];
      for (let index = 1; index <= 12; index++) {
        const element = data
          .filter((elm) => elm.mois == index)
          .reduce((a, b) => a + (b[col] || 0), 0);

        obj2.push(element.toFixed(2));
      }
      return obj2;
    },
    async dettes_creances() {
      this.listheaders = this.dettes_headers;
      this.title = "Liste des Creances ";
      let v;
      let cr = "";
      if (myBL == "b") {
        v = {
          where: { column: "SITUATION_GL", operator: "NEQ", value: 0 },
          orderBy: [{ column: "SITUATION_GL", order: "DESC" }],
          TypeScope: 2,
          date_debut: this.$store.state.exercice.toString() + "-01-01",
          date_fin: this.$store.state.today,
        };
        this.dettes_headers[3].value = "tot_mvm";
        this.dettes_headers[3].total = "tot_mvm";
        this.dettes_headers[4].value = "tot_paye";
        this.dettes_headers[4].total = "tot_paye";
        this.dettes_headers[5].value = "situation_gl";
        this.dettes_headers[5].total = "situation_gl";
      } else {
        switch (this.tabs[1]) {
          case 0:
            cr = "SITUATION_GL";
            this.dettes_headers[3].value = "tot_mvm";
            this.dettes_headers[3].total = "tot_mvm";
            this.dettes_headers[4].value = "tot_paye";
            this.dettes_headers[4].total = "tot_paye";
            this.dettes_headers[5].value = "situation_gl";
            this.dettes_headers[5].total = "situation_gl";
            break;
          case 1:
            cr = "SITUATION_FACT";
            break;
          case 2:
            cr = "SITUATION_ENL";
            this.dettes_headers[3].value = "tot_enl";
            this.dettes_headers[3].total = "tot_enl";
            this.dettes_headers[4].value = "paye_enl";
            this.dettes_headers[4].total = "paye_enl";
            this.dettes_headers[5].value = "situation_enl";
            this.dettes_headers[5].total = "situation_enl";
            break;
          default:
            cr = "SITUATION_FACT";
            break;
        }
        v = {
          where: { column: cr, operator: "NEQ", value: 0 },
          orderBy: [{ column: cr, order: "DESC" }],
          TypeScope: 2,
          date_debut: this.$store.state.exercice.toString() + "-01-01",
          date_fin: this.$store.state.today,
        };
      }

      let r = await this.requette(SIT_TIERS_ALL, v);
      this.width = "1300px";
      if (r) this.list = r.tiers_sit_all;
      this.isListClose = false;
      this.fl++;
    },
    closeListForm() {
      this.isListClose = true;
    },
    async load_prod() {
      let v = {
        where: {
          AND: [
            {
              column: "CMD_TYPE",
              value: 1,
            },
            {
              column: "STATUT_ID",
              value: 7,
            },
          ],
        },
      };
      this.progress3 = true;
      let r;
      if (
        this.$store.state.auth.includes("01073") ||
        this.$store.state.isadmin
      ) {
        r = await this.requette(ALLDETAILSCMDS, v);
        if (r) {
          this.cmds = r.alldetailscmds;
        }
      } else if (
        this.$store.state.auth.includes("01035") ||
        this.$store.state.isadmin
      ) {
        r = await this.requette(ALLORDERS, v);
        if (r) {
          this.cmds = r.allorders;
        }
      }

      this.progress3 = false;
      let chdata = {
        type: "bar",
        data: {
          labels: [],
          units: [],
          prc_lanc: [],
          prc_fab: [],
          prc_receipt: [],
          prc_nc: [],
          datasets: [
            {
              label: "Commandé ",
              data: [],
              backgroundColor: "#e8c3b9",
              borderColor: "#e8c3b9",
              borderWidth: 1,
            },
            {
              label: "Lancé ",
              data: [],
              backgroundColor: "#8e5ea2",
              borderColor: "#8e5ea2",
              borderWidth: 1,
            },
            {
              label: "Fabriqué",
              data: [],
              backgroundColor: "#3e95cd",
              borderColor: "#3e95cd",
              borderWidth: 1,
            },
            {
              label: "Réception",
              data: [],
              backgroundColor: "#008000",
              borderColor: "#008000",
              borderWidth: 1,
            },
            {
              label: "Non conforme",
              data: [],
              backgroundColor: "#FF0000",
              borderColor: "#FF0000",
              borderWidth: 1,
            },
          ],
        },

        options: {
          title: {
            display: true,
            text: "",
            position: "bottom",
            fontSize: 20,
            fontStyle: "bold",
          },

          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Quantité",
                },
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";

                if (label) {
                  label += " : " + numberWithSpace(tooltipItem.yLabel);
                }
                if (data.units[tooltipItem.index])
                  label += " " + data.units[tooltipItem.index];
                let taux = 0;
                if (tooltipItem.datasetIndex == 1)
                  taux = data.prc_lanc[tooltipItem.index];
                if (tooltipItem.datasetIndex == 2)
                  taux = data.prc_fab[tooltipItem.index];
                if (tooltipItem.datasetIndex == 3)
                  taux = data.prc_receipt[tooltipItem.index];
                if (tooltipItem.datasetIndex == 4)
                  taux = data.prc_nc[tooltipItem.index];
                if (taux) label += " (" + taux + "%)";

                return label;
              },
            },
          },
        },
      };
      chdata.data.labels = this.cmds.map((elm) => elm.label);
      chdata.data.units = this.cmds.map((elm) => elm.unit);
      chdata.data.prc_lanc = this.cmds.map((elm) => elm.prc_lanc);
      chdata.data.prc_fab = this.cmds.map((elm) => elm.prc_fab);
      chdata.data.prc_receipt = this.cmds.map((elm) => elm.prc_receipt);
      chdata.data.prc_nc = this.cmds.map((elm) => elm.prc_nc);
      chdata.data.datasets[0].data = this.cmds.map((elm) => elm.qte);
      chdata.data.datasets[1].data = this.cmds.map((elm) => elm.qte_lanc);
      chdata.data.datasets[2].data = this.cmds.map((elm) => elm.qte_fab);
      chdata.data.datasets[3].data = this.cmds.map((elm) => elm.qte_receipt);
      chdata.data.datasets[4].data = this.cmds.map((elm) => elm.qte_nc);
      var ctx = document.getElementById("product");
      new Chart(ctx, chdata);
    },
    load_data() {
      //chiffre d'affaire

      let mcn = [];
      let mcn1 = [];
      let mfn = [];
      let mfn1 = [];
      let men = [];
      let men1 = [];
      let k_list = [];
      let creance,
        creance_fact,
        creance_enl,
        creance_ech,
        creance_fact_ech,
        creance_enl_ech;

      mcn = this.data_output(this.mois_n, this.bl ? "ttc_gl" : "ttc_fact");

      mcn1 = this.data_output(this.mois_n1, this.bl ? "ttc_gl" : "ttc_fact");

      // fact
      mfn = this.data_output(this.mois_n, "ttc_fact");
      mfn1 = this.data_output(this.mois_n1, "ttc_fact");
      // enlv
      men = this.data_output(this.mois_n, "ttc_enl");
      men1 = this.data_output(this.mois_n1, "ttc_enl");
      let ca_an = 0;
      let ca_an_fact = 0;
      let ca_an_enl = 0;
      ca_an = mcn.reduce((a, b) => parseFloat(a) + (parseFloat(b) || 0), 0);

      ca_an_fact = mfn.reduce(
        (a, b) => parseFloat(a) + (parseFloat(b) || 0),
        0
      );
      ca_an_enl = men.reduce((a, b) => parseFloat(a) + (parseFloat(b) || 0), 0);

      //creance

      creance = this.creance_list[0].creance.toFixed(2);

      creance_fact = this.creance_list[0].creance_fact.toFixed(2);

      creance_enl = this.creance_list[0].creance_enl.toFixed(2);

      // creance_ech = this.creance_echu
      //   .map((elm) => elm.creance)
      //   .reduce((a, b) => a + (b || 0), 0)
      //   .toFixed(2);

      // creance_fact_ech = this.creance_echu
      //   .map((elm) => elm.creance_fact)
      //   .reduce((a, b) => a + (b || 0), 0)
      //   .toFixed(2);

      // creance_enl_ech = this.creance_echu
      //   .map((elm) => elm.creance_enl)
      //   .reduce((a, b) => a + (b || 0), 0)
      //   .toFixed(2);
      //kpis
      let kpis = this.exercices;

      let kpi = 0;
      if (kpis.length > 0) {
        kpi = kpis[0].kpi * kpis[0].unit;
        for (let index = 1; index <= 12; index++) {
          let i = index < 10 ? "0" + index : index;
          k_list.push(kpis[0]["k" + i] * kpis[0].unit);
        }
      }
      let chdata = {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: "CA ",
              data: [],
              backgroundColor: "#66BB6A",
            },
            {
              label: "CA ",
              data: [],
              backgroundColor: "#B39DDB",
              hidden: false,
            },
            {
              label: "Objectif",
              type: "line",
              data: [],
              backgroundColor: "#3e95cd",
              borderColor: "#3e95cd",
              borderWidth: 1,
              fill: false,
              hidden: false,
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "",
            position: "bottom",
            fontSize: 20,
            fontStyle: "bold",
          },
          legend: {
            display: true,
          },
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: unit_ca,
                },
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";

                if (label) {
                  label += " : ";
                }
                label += numberWithSpace(tooltipItem.yLabel.toFixed(2)) + " DA";
                let taux = 0;

                if (tooltipItem.datasetIndex >= 1) {
                  if (
                    data.datasets[2].data[tooltipItem.index] &&
                    data.datasets[1].data[tooltipItem.index]
                  ) {
                    taux =
                      (data.datasets[1].data[tooltipItem.index] /
                        data.datasets[2].data[tooltipItem.index]) *
                      100;

                    taux = parseFloat(taux.toFixed(2));
                    label += " (" + taux + "%)";
                  }
                }

                return label;
              },
            },
          },
        },
      };
      chdata.data.labels = this.mois_list;
      chdata.data.datasets[0].label = "CA (" + (this.exercice - 1) + ")";
      chdata.data.datasets[0].data = mcn1;
      chdata.data.datasets[1].label = "CA (" + this.exercice + ")";
      chdata.data.datasets[1].data = mcn;
      chdata.data.datasets[2].data = k_list;
      chdata.options.title.text = myName;

      this.chart_data.push({
        ca: ca_an,
        ca_fact: ca_an_fact,
        ca_enl: ca_an_enl,
        creance: creance,
        creance_fact: creance_fact,
        creance_enl: creance_enl,
        creance_ech: creance_ech,
        creance_fact_ech: creance_fact_ech,
        creance_enl_ech: creance_enl_ech,
        kpi: kpi,
        taux_kpi: parseFloat((kpi > 0 ? (ca_an / kpi) * 100 : 0).toFixed(2)),
        mcn: mcn,
        mcn1: mcn1,
        mfn: mfn,
        mfn1: mfn1,
        men: men,
        men1: men1,
        kpi_list: k_list,
        chdata: chdata,
      });
      var ctx = document.getElementById(1);
      let ch = new Chart(ctx, this.chart_data[0].chdata);
      this.chart_data[0].ch = ch;
    },
    ca_exercice() {
      let ca = 0;
      if (this.chart_data.length > 0) {
        if (myBL == "b") ca = this.chart_data[0].ca_fact;
        else {
          switch (this.tabs[1]) {
            case 0:
              ca = this.chart_data[0].ca;
              break;
            case 1:
              ca = this.chart_data[0].ca_fact;
              break;
            case 2:
              ca = this.chart_data[0].ca_enl;
              break;
            default:
              ca = this.chart_data[0].ca_fact;
              break;
          }
        }
      }
      return ca;
    },
    kpi_value() {
      let v = 0;
      if (this.chart_data.length > 0) v = this.chart_data[0].kpi / this.c;
      return v;
    },
    kpi_taux() {
      let v = 0;
      if (this.chart_data.length > 0) v = this.chart_data[0].taux_kpi;
      return v;
    },
    creance() {
      let cr = 0;
      let cre = 0;
      if (this.chart_data.length > 0) {
        if (myBL == "b") {
          cr = this.chart_data[0].creance / this.c;
          cre = this.chart_data[0].creance_ech / this.c;
        } else
          switch (this.tabs[1]) {
            case 0:
              cr = this.chart_data[0].creance / this.c;
              cre = this.chart_data[0].creance_ech / this.c;
              break;
            case 1:
              cr = this.chart_data[0].creance_fact / this.c;
              cre = this.chart_data[0].creance_fact_ech / this.c;
              break;
            case 2:
              cr = this.chart_data[0].creance_enl / this.c;
              cre = this.chart_data[0].creance_enl_ech / this.c;
              break;
            default:
              cr = this.chart_data[0].creance_fact / this.c;
              cre = this.chart_data[0].creance_fact_ech / this.c;
              break;
          }
      }
      return { cr: cr, cre: cre ? cre : 0 };
    },
    periode_change() {
      switch (this.ca_by) {
        case 0:
          this.chart_data[0].chdata.data.labels = this.mois_list;
          this.chart_data[0].chdata.data.datasets[0].type = "bar";
          this.chart_data[0].chdata.data.datasets[0].fill = true;
          if (this.tabs[1] == 0) {
            this.chart_data[0].chdata.data.datasets[1].data =
              this.chart_data[0].mcn;
            this.chart_data[0].chdata.data.datasets[0].data =
              this.chart_data[0].mcn1;
          }
          if (this.tabs[1] == 1) {
            this.chart_data[0].chdata.data.datasets[1].data =
              this.chart_data[0].mfn;
            this.chart_data[0].chdata.data.datasets[0].data =
              this.chart_data[0].mfn1;
          }
          if (this.tabs[1] == 2) {
            this.chart_data[0].chdata.data.datasets[1].data =
              this.chart_data[0].men;
            this.chart_data[0].chdata.data.datasets[0].data =
              this.chart_data[0].men1;
          }
          this.chart_data[0].chdata.data.datasets[0].label =
            "CA (" + (this.exercice - 1) + ")";

          this.chart_data[0].chdata.data.datasets[1].label =
            "CA (" + this.exercice + ")";
          this.chart_data[0].chdata.options.legend.display = true;
          this.chart_data[0].chdata.data.datasets[0].hidden = false;
          this.chart_data[0].chdata.data.datasets[1].hidden = false;
          this.chart_data[0].chdata.data.datasets[2].hidden = false;
          break;
        case 1:
          this.chart_data[0].chdata.data.labels = this.weeks.map(
            (elm) => "Sem " + elm.week
          );
          this.chart_data[0].chdata.data.datasets[0].data = [];
          if (this.tabs[1] == 0)
            this.chart_data[0].chdata.data.datasets[1].data = this.weeks.map(
              (elm) => (elm.ttc_gl ? parseFloat(elm.ttc_gl.toFixed(2)) : 0)
            );
          if (this.tabs[1] == 1)
            this.chart_data[0].chdata.data.datasets[1].data = this.weeks.map(
              (elm) => (elm.ttc_fact ? parseFloat(elm.ttc_fact.toFixed(2)) : 0)
            );
          if (this.tabs[1] == 2)
            this.chart_data[0].chdata.data.datasets[1].data = this.weeks.map(
              (elm) => (elm.ttc_enl ? parseFloat(elm.ttc_enl.toFixed(2)) : 0)
            );
          this.chart_data[0].chdata.options.legend.display = false;
          this.chart_data[0].chdata.data.datasets[0].hidden = true;
          this.chart_data[0].chdata.data.datasets[1].hidden = false;
          this.chart_data[0].chdata.data.datasets[2].hidden = true;
          break;
        case 2:
          this.chart_data[0].chdata.data.labels = this.days.map(
            (elm) => elm.opr_date
          );
          this.chart_data[0].chdata.data.datasets[0].data = [];
          if (this.tabs[1] == 0)
            this.chart_data[0].chdata.data.datasets[1].data = this.days.map(
              (elm) => (elm.ttc_gl ? parseFloat(elm.ttc_gl.toFixed(2)) : 0)
            );
          if (this.tabs[1] == 1)
            this.chart_data[0].chdata.data.datasets[1].data = this.days.map(
              (elm) => (elm.ttc_fact ? parseFloat(elm.ttc_fact.toFixed(2)) : 0)
            );
          if (this.tabs[1] == 2)
            this.chart_data[0].chdata.data.datasets[1].data = this.days.map(
              (elm) => (elm.ttc_enl ? parseFloat(elm.ttc_enl.toFixed(2)) : 0)
            );
          this.chart_data[0].chdata.options.legend.display = false;
          this.chart_data[0].chdata.data.datasets[0].hidden = true;
          this.chart_data[0].chdata.data.datasets[1].hidden = false;
          this.chart_data[0].chdata.data.datasets[2].hidden = true;
          break;
      }
      this.chart_data[0].ch.update();
    },
    async sit_change() {
      switch (this.tabs[1]) {
        case 0:
          this.chart_data[0].chdata.data.datasets[1].data =
            this.chart_data[0].mcn;
          this.chart_data[0].chdata.data.datasets[0].data =
            this.chart_data[0].mcn1;

          break;
        case 1:
          this.chart_data[0].chdata.data.datasets[1].data =
            this.chart_data[0].mfn;
          this.chart_data[0].chdata.data.datasets[0].data =
            this.chart_data[0].mfn1;

          break;
        case 2:
          this.chart_data[0].chdata.data.datasets[1].data =
            this.chart_data[0].men;
          this.chart_data[0].chdata.data.datasets[0].data =
            this.chart_data[0].men1;

          break;
        default:
          break;
      }
      this.chart_data[0].chdata.data.datasets[2].data =
        this.chart_data[0].kpi_list;
      this.chart_data[0].ch.update();
      this.load_tier(this.tabs[1]);
      this.cat_change();
    },
    async cat_change() {
      let result = [];
      //let label = "";
      this.cats.forEach((element) => {
        result = result.concat(
          this.list_cat_all
            .filter((elm) => elm.fk_parent == element)
            .map((elm) => elm.id)
        );
        //  label = this.list_cat_all.find((elm) => elm.id == element).label;
      });
      this.tabs[1];
      if (result.length > 0) {
        this.progress4 = true;
        let v = {
          categorie_id: result,
          year: this.exercice,
          facture: this.tabs[1] == 0 ? [0, 1] : this.tabs[1] == 1 ? [1] : [0],
        };

        let r = await this.requette(
          this.categorieType == 1 ? CA_TIER_CAT : CA_PRODUCT,
          v
        );
        this.progress4 = false;
        if (r) {
          // let backgroundColor = [];
          let ca = this.ca_exercice();
          this.cat_list =
            this.categorieType == 1 ? r.ca_tier_cat : r.ca_product;
          let tot = this.cat_list
            .map((elm) => elm.ttc_gl)
            .reduce((a, b) => a + (b || 0), 0);
          if (tot < ca) {
            this.cat_list.push({ categorie_name: "Autre", ttc_gl: ca - tot });
          }
          this.cat_list.forEach((element) => {
            element.prc_ca = ((element.ttc_gl / (ca ? ca : 1)) * 100).toFixed(
              2
            );
          });
          let k = this.cat_list.findIndex(
            (elm) => elm.categorie_name == "Autre"
          );
          let autre;
          if (k >= 0) {
            autre = this.cat_list[k];
            this.cat_list.splice(k, 1);
          }
          this.cat_list.sort(compare);
          if (autre) this.cat_list.push(autre);
          this.klist++;
          // for (let index = 0; index < result.length; index++) {
          //   let i = this.list_cat_all.findIndex(
          //     (elm) => elm.id == result[index]
          //   );
          //   if (i >= 0) {
          //     if (this.list_cat_all[i].color)
          //       backgroundColor.push(this.list_cat_all[i].color);
          //     else backgroundColor.push(dynamicColors());
          //   } else backgroundColor.push(dynamicColors());
          // }
          // planetChartData2.data.datasets[0].backgroundColor = backgroundColor;
          // planetChartData2.data.labels = cat.map((elm) => elm.categorie_name);

          // planetChartData2.data.datasets[0].label = label;
          // planetChartData2.data.datasets[0].data = cat.map((elm) =>
          //   parseFloat(elm.ttc_gl.toFixed(2))
          // );
          // planetChartData2.options.title.text =
          //   "Repartition du chiffre d'affaire par categorie " +
          //   (this.categorieType == 1 ? " de client" : " de produit");
          // if (this.ch2 == 0) {
          //   this.ch2++;
          //   this.ch_cat = await new Chart(
          //     document.getElementById("planetchart3"),
          //     planetChartData2
          //   );
          // }
          // if (this.ch2 > 0 && this.ch_cat) this.ch_cat.update();
        }
      }
    },
    async load_tier(tabs) {
      let v;
      let a;
      if (myBL == "b") {
        v = "ttc_fact";
        a = "tier_fact";
      } else {
        switch (tabs) {
          case 0:
            v = "ttc_gl";
            a = "tier_gl";
            break;
          case 1:
            v = "ttc_fact";
            a = "tier_fact";
            break;
          case 2:
            v = "ttc_enl";
            a = "tier_enl";
            break;
          default:
            v = "ttc_gl";
            a = "tier_gl";
            break;
        }
      }
      let v1 = {
        year: this.exercice,
        tiertype: 2,
        taux: this.taux ? parseFloat(this.taux) : 5,
      };
      this.progress2 = true;
      let r = await this.requette(CA_TIER, v1);
      if (r) this.tiers = r.ca_tier;
      this.progress2 = false;
      // let result = [];
      let cum = 0;
      let j = 0;
      let ca = this.ca_exercice();
      this.tier_list = [];
      this.tiers.forEach((element) => {
        let i = this.tier_list.findIndex((elm) => elm.tier_name == element[a]);
        if (i == -1) {
          j++;
          this.tier_list.push({
            id: j,
            tier_name: element[a],
            ttc_gl: parseFloat(element[v].toFixed(2)),
            prc_ca: ((element[v] / (ca ? ca : 1)) * 100).toFixed(2),
          });
        } else {
          cum = this.tier_list[i].ttc_gl + element[v];
          this.tier_list[i].ttc_gl = parseFloat(cum.toFixed(2));
          if (ca > 0)
            this.tier_list[i].prc_ca = parseFloat(
              ((cum / ca) * 100).toFixed(2)
            );
        }
      });
      let k = this.tier_list.findIndex((elm) => elm.tier_name == "Autre");
      let autre;
      if (k >= 0) {
        autre = this.tier_list[k];
        this.tier_list.splice(k, 1);
      }
      this.tier_list.sort(compare);
      if (autre) this.tier_list.push(autre);

      this.klist1++;
      // let backgroundColor = [];
      // for (let index = 0; index < result.length; index++) {
      //   backgroundColor.push(dynamicColors());
      // }

      // let l = result.map((elm) => elm.tier_name);
      // planetChartData2.data.datasets[0].backgroundColor = backgroundColor;
      // planetChartData2.data.labels = l;
      // planetChartData2.data.datasets[0].label = l;
      // planetChartData2.data.datasets[0].data = result.map((elm) => elm.value);
      // planetChartData2.options.title.text =
      //   "Repartition du chiffre d'affaire par client ";
      // if (this.ch1 == 0) {
      //   this.ch1++;
      //   this.ch_tier = await new Chart(
      //     document.getElementById("planetchart2"),
      //     planetChartData2
      //   );
      // }
      // if (this.ch1 > 0 && this.ch_tier) this.ch_tier.update();
    },
    CategorieChange(type) {
      let list = this.list_cat_all.filter((elm) => elm.type_cat == type);
      list.forEach((element) => {
        if (!list.map((elm2) => elm2.fk_parent).includes(element.id))
          element.locked = true;
      });

      list = list.filter((elm) => !(elm.fk_parent == "0" && elm.locked));
      this.list_cat = list;
    },
    async requette(query, v) {
      let r;
      this.Loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.Loading = false;
        })
        .catch((error) => {
          this.Loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },
  },
};
</script>
